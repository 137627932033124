<template>
  <b-card>
    <div class="demo-inline-spacing mb-2">
      <h4
        class="card-title"
        style="margin:0"
      >
        인플루언서 정보
      </h4>
      <b-badge
        class="ml-1"
        variant="success"
        style="margin:0"
      >
        가계정
      </b-badge>
    </div>
    <b-row class="mb-1">
      <b-col
        cols="6"
        xl="2"
        lg="2"
        md="4"
      >
        <b-avatar
          :text="avatarText(influencerData.name)"
          variant="light-primary"
          size="104px"
          rounded
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        xl="6"
        lg="6"
      >

        <b-form-group>
          <label>Name</label>
          <tagby-copyable-input v-model="influencerData.name" />
        </b-form-group>

        <b-form-group>
          <label>Phone</label>
          <tagby-copyable-input v-model="influencerData.phone" />
        </b-form-group>

      </b-col>

      <b-col
        cols="12"
        xl="6"
        lg="6"
      >
        <b-row>
          <b-col
            cols="12"
            xl="6"
            lg="6"
          >
            <b-form-group>
              <label>Gender</label>
              <v-select
                :value="genderSelected"
                :options="genderOptions"
                @input="$emit('input-gender', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="6"
            lg="6"
          >
            <b-form-group>
              <label>Job</label>
              <b-form-input
                v-model="influencerData.job"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            xl="6"
            lg="6"
          >
            <b-form-group>
              <label>Birth</label>
              <b-form-datepicker
                v-model="influencerData.birth"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            xl="6"
            lg="6"
          >
            <b-form-group>
              <label>Country</label>
              <b-form-input
                v-model="influencerData.country"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BBadge,
    vSelect,
    TagbyCopyableInput,
  },
  props: {
    influencerData: {
      type: Object,
      required: true,
    },
    genderSelected: {
      type: Object,
      default: null,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'input-gender',
  ],
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
