<template>
  <b-row>
    <b-col
      cols="12"
      xl="8"
      lg="8"
      md="7"
    >
      <influencer-info-card
        :influencer-data="influencerData"
        :gender-selected="genderSelected"
        :gender-options="genderOptions"
        @input-gender="setGender"
      />
    </b-col>

    <b-col
      cols="12"
      xl="4"
      lg="4"
      md="5"
    >
      <action-card
        :is-saving="isSaving"
        :is-valid="isValid"
        @create="createInfluencer"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import InfluencerCreateViewModel from './viewModel'
import InfluencerInfoCard from './components/InfluencerInfoCard.vue'
import ActionCard from './components/ActionCard.vue'

export default {
  components: {
    BRow,
    BCol,

    InfluencerInfoCard,
    ActionCard,
  },
  setup() {
    return {
      ...InfluencerCreateViewModel(),
    }
  },
}
</script>
