<template>
  <b-input-group>
    <b-form-input
      :value="value"
      :disabled="disabled"
      @input="$emit('input', $event)"
      @focus="$emit('focus')"
    />
    <b-input-group-append
      is-text
      class="cursor-pointer copy-button"
      @click="makeCopyToast"
    >
      <feather-icon
        icon="CopyIcon"
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const toast = useToast()
    const makeCopyToast = () => {
      if (props.value !== null && props.value !== '') {
        window.navigator.clipboard.writeText(props.value)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Copy Success',
            icon: 'BellIcon',
            text: props.value,
            variant: 'primary',
          },
        })
      }
    }

    return {
      makeCopyToast,
    }
  },
}
</script>

<style>
  .copy-button:hover {
    filter: invert(100%)
  }
</style>
