<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        xl="6"
        class="mb-1"
      >
        <b-button
          variant="outline-primary"
          :disabled="isSaving || !isValid"
          block
          @click="$emit('create')"
        >
          <b-spinner
            v-if="isSaving"
            small
          />
          저장
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    isSaving: {
      type: Boolean,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },
  emtis: [
    'create',
  ],
}
</script>
