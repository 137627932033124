import {
  ref,
  onMounted,
  onUnmounted,
  computed,
  watch,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import InfluencerCreateStoreModule from './storeModule'

export default function () {
  // 모든 기능에서 공통적으로 사용
  const APP_STORE_MODULE_NAME = 'app-influencer-create'

  const toast = useToast()
  const makeToast = (variant, title, text) => toast({
    component: ToastificationContent,
    props: {
      title,
      text,
      variant,
      icon: 'AlertTriangleIcon',
    },
  })

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, InfluencerCreateStoreModule)
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  const influencerData = ref({
    name: null,
    phone: null,
    gender: null,
    job: null,
    birth: null,
    country: null,
  })
  const isSaving = ref(false)
  const isValid = computed(() => Boolean(influencerData.value.name))
  const genderOptions = [
    { label: '남성', value: 'M' },
    { label: '여성', value: 'F' },
  ]
  const genderSelected = computed({
    get() {
      return genderOptions.find(option => option.value === influencerData.value.gender)
    },
    set(item) {
      influencerData.value.gender = item.value
    },
  })
  const setGender = item => {
    genderSelected.value = item
  }
  const createInfluencer = () => {
    isSaving.value = true
    store.dispatch(`${APP_STORE_MODULE_NAME}/createInf`,
      {
        name: influencerData.value.name,
        phone: influencerData.value.phone,
        gender: influencerData.value.gender,
        job: influencerData.value.job,
        birth: influencerData.value.birth,
        country: influencerData.value.country,
      })
      .then(response => {
        makeToast('primary', '인플루언서 생성에 성공했습니다.')
        router.replace({
          name: 'member-inf-detail',
          params: { idx: response.data.data.idx },
        }).catch(() => { })
      })
      .catch(error => {
        if (error.response.status === 400) {
          makeToast('danger', '인플루언서 생성에 실패했습니다.', '동일한 휴대번호가 존재합니다.')
        } else {
          makeToast('danger', '인플루언서 생성에 실패했습니다.')
        }
      })
      .finally(() => {
        isSaving.value = false
      })
  }

  return {
    influencerData,
    isSaving,
    isValid,
    genderOptions,
    genderSelected,
    setGender,
    createInfluencer,
  }
}
